import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const ContactUsCta = () => {
  return (
    <div className="background-colour-beige-1">
      <div className="container py-5 text-center">
        <StaticImage
          alt="little hair lounge logo"
          placeholder="blurred"
          height={200}
          loading="lazy"
          src={'../../../images/lhl-logo-cropped.png'}
        ></StaticImage>
        <h2>More Questions?</h2>
        <Link to="/contact" className="links">
          Contact Us
        </Link>
        <br></br>
      </div>
    </div>
  )
}

export default ContactUsCta
